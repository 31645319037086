<template>
  <span :class="classButton + 'svg-edit-container noMargin'" @click="action()">
    <span class="tooltip" >{{ tooltipText }}</span>
    <img :src="bgColor" width="18" alt="">
    <!-- <span :class="'w-15 h-15 rounded ' + bgColor"></span> -->
  </span>
</template>

<script>
export default {
  props: {
    iconColor: {
      type: String,
      default: "#FFFFFF",
    },
    isButton: {
      type: Boolean,
      default: false,
    },
    bgColor:{
      type: String,
      default: 'bg-green'
    },
    tooltipText:{
      type: String,
      default: 'Em Stock'
    }
  },
  data() {
    return {
      classButton: this.isButton ? "btn-icon " : "",
      isMobile: window.matchMedia("(max-width: 768px)").matches,
    };
  },
  created() {
    window.addEventListener("resize", this.updateIsMobile);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateIsMobile);
  },
  methods: {
    action() {
      // if (this.isMobile) {
      //   const tooltip = document.getElementsByClassName('tooltip');
      // }
      this.$emit("action");
    },
    updateIsMobile() {
      this.isMobile = window.matchMedia("(max-width: 768px)").matches;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Icons";

.w-15{
  display: inline-block;
  width: 15px;
}
.h-15{
  height: 15px;
}

.rounded{
  border-radius: 100%;
}
.noMargin{
  margin: 0px;
}
</style>
