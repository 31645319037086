<template>
  <span :class="classButton + 'svg-edit-container'" @click="action() " >
    <span class="tooltip"><slot/></span>
    <img :src="imgUrl" alt="urlImage" width="40" style="cursor:pointer;" />
  </span>
</template>

<script>
export default {
  name: "imgIconPopup",
  props: {
    imgUrl: {
      type: String,
      default: "#FFFFFF",
    },
    isButton: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
      default: "Em Stock",
    },
  },
  data() {
    return {
      classButton: this.isButton ? "btn-icon " : "",
      isMobile: window.matchMedia("(max-width: 768px)").matches,
    };
  },
  created() {
    window.addEventListener("resize", this.updateIsMobile);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateIsMobile);
  },
  methods: {
    action() {
      // if (this.isMobile) {
      //   const tooltip = document.getElementsByClassName('tooltip');

      // }
      this.$emit("action");
    },
    updateIsMobile() {
      this.isMobile = window.matchMedia("(max-width: 768px)").matches;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Popover";

.w-15 {
  display: inline-block;
  width: 15px;
}
.h-15 {
  height: 15px;
}

.rounded {
  border-radius: 100%;
}
</style>
