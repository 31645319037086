<template>
  <div class="mainContainer text-highlight">

    <div v-if="false">
      {{ translateString("stockDescription") }}
      <div class="containerItems">
        <div class="containerItems-itemdiv sm-text">
          <img :src="getInfoByStock(2, true)" width="18" alt="">
          {{ translateString("outStock") }}
        </div>
        <div class="containerItems-itemdiv sm-text">
          <img :src="getInfoByStock(7, true)" width="18" alt="">
          {{ translateString("lowStock") }}
        </div>
        <div class="containerItems-itemdiv sm-text">
          <img :src="getInfoByStock(12, true)" width="18" alt="">
          {{ translateString("inStock") }}
        </div>
      </div>
    </div>
    <div>
      {{ translateString("tireFeature") }}
      <div class="containerItems">
        <div class="containerItems-itemdiv sm-text">
          <div >
            <img :src="urlGasolinera" alt="" width="30" />
          </div>
          <p>
            <b> {{ translateString("efficiency")}}: </b>
            {{ translateString("descriptionEfficience") }}
          </p>
        </div>
        <div class="containerItems-itemdiv sm-text">
          <div>
            <img :src="urlCloud" alt="" width="30" />
          </div>
          <p>
            <b>{{ translateString("safety") }}:</b>
            {{ translateString("descriptionSafety") }}
          </p>
        </div>
        <div class="containerItems-itemdiv sm-text">
          <div>
            <img :src="urlNoise" alt="" width="30" />
          </div>
          <p>
            <b>{{ translateString("noise") }}:</b>
            {{ translateString("descriptionNoise") }}
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { translateString } from "@/functions/functions.js";
import { getInfoByStock } from "@/functions/functions.js";
import gasolinera96 from "@/assets/icons/gasolinera-96.png";
import rainingCloud from "@/assets/icons/lluvia-96.png";
import noiseIcon from "@/assets/icons/volumen-96.png";
export default {
  name: "DescriptionColors",
  components: {
  },
  props: {},
  setup() {
    return {
      urlGasolinera: gasolinera96,
      urlCloud: rainingCloud,
      urlNoise: noiseIcon,
    };
  },
  methods: {
    translateString,
    getInfoByStock,
  },
};
</script>

<style lang="scss" scoped>
*{
  color: $bold;
}
.mainContainer {
  background-color: white; 
  padding: 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  //justify-content: space-around;
  justify-content: space-around;

  @include respond-to-min("md") {
    width: 100%;
    //min-height: 150px;
    min-height: 80px;
  }
  @include respond-to-max("md") {
    width: 100%;
    height: 50%;
  }
}

.containerItems{
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  gap: 4px;
  justify-content: space-between;
  text-align: justify;
  margin-top: 5px;
  @include respond-to-min("md") {
    flex-direction: column;
  }

  @include respond-to-max("md") {
    flex-direction: column;
  }
  &-itemdiv{
    display: grid;
    grid-template-columns: 15% 85%;
    align-items: center;
    @include respond-to-min("md") {
      margin: 10px 0px !important;
    }
    @include respond-to-max("md") {
      gap: 4px;
      margin: 10px 0px !important;
    }
  }
}

.w-15 {
  display: inline-block;
  width: 15px;
}
.h-15 {
  height: 15px;
}

.rounded {
  border-radius: 100%;
}
.noMargin {
  margin: 0px;
}
div {
  margin: 0px;
}


</style>
