<template>
  <div>
    <Card class="w-full">
      <CheckboxContainer
        v-for="(detail, index) in $props.tyresDetails"
        :key="index"
        class="w-full"
        checkboxClass="checkbox-round radio-checkbox "
        containerClass="left"
        inputType="checkbox"
        :id="'detail-' + index"
        :value="`${detail.frontTyreSize}-${detail.rearTyreSize}`"
        :name="'detail-' + index"
        :isChecked="
          selectedTyreSize.includes(
            String(`${detail.frontTyreSize}-${detail.rearTyreSize}`)
          )
        "
        @updateInput="updateInputDetail($event)"
      >
        <LabelComplex :forLabel="'detail-' + index">
          <div class="row">
            <div>
              <p class="text">
                {{
                  `${detail.frontTyreSize}  ${
                    detail.rearTyreSize === detail.frontTyreSize
                      ? ""
                      : " - " + detail.rearTyreSize
                  }`
                }}
              </p>
            </div>
          </div>
        </LabelComplex>
      </CheckboxContainer>
    </Card>
  </div>
</template>

<script>
import { translateString, getInfoByStock } from "@/functions/functions.js";

import Card from "@/components/Card/Card.vue";
import CheckboxContainer from "@/components/Checkbox/CheckboxContainer.vue";
import LabelComplex from "@/components/Checkbox/LabelComplex.vue";

export default {
  name: "Step2",
  components: {
    Card,
    CheckboxContainer,
    LabelComplex,
  },
  props: {
    tyresDetails: { type: Array, default: () => [] },
    selectedTyre: { type: Array, default: () => [] },
  },
  data() {
    return {
      selectedDetailsReferences: [],
      selectedTyreSize: "",
    };
  },
  created() {
    if (this.$props.selectedTyre && this.$props.selectedTyre.length > 0) {
      this.selectedTyreSize =
        this.$props.selectedTyre.length === 1
          ? `${this.$props.selectedTyre[0]}-${this.$props.selectedTyre[0]}`
          : this.$props.selectedTyre.join("-");
    }
    this.selectedReferencesStore.forEach((element) => {
      this.selectedDetailsReferences.push(element.modelName);
    });
  },

  methods: {
    translateString,
    getInfoByStock,

    updateInputDetail(data) {
      const id = data.id.replace("detail-", "");
      if (data.checked) {
        this.selectedTyreSize = `${this.tyresDetails[id]?.frontTyreSize}-${this.tyresDetails[id]?.rearTyreSize}`;
      } else {
        this.selectedTyreSize = "";
      }
      this.$emit("updateTyreSize", this.selectedTyreSize);
    },
  },

  computed: {
    selectedReferencesStore() {
      return this.$store.getters.getSelectedReferences;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../PneusForm.scss";
</style>
