<template>
  <div class="container-pneus_item">
    <div class="popup-text">
      <h2 class="xl-title-boldest">{{ $props.name }}</h2>
      <div class="subtitle">
        <p class="sm-text-white">
          {{ $props.description }}
        </p>
        <slot name="info"></slot>
        </div>
    </div>
    <div class="list-container mb-2">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    name: { type: String, default: () => "" },
    description: { type: String, default: () => "" },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.container-pneus_item{
  margin-top: 0px;
  display: grid;
  gap: 5px;
  grid-template-rows: 20% 80fr;
}
.subtitle{
  margin-top: 0px;
  display:  flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}
.list-container{
  min-height: 60%;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
