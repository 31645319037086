var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_container"},[_vm._l((_vm.stepsPneus),function(item){return _c('FrameBasic',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedStep === item.stepId),expression:"selectedStep === item.stepId"}],key:item.stepId,attrs:{"name":item.title,"description":(_vm.selectedStep !== 3 && _vm.selectedStep !== 4 && _vm.selectedStep !== 1) ||
      ((_vm.selectedStep === 3 || _vm.selectedStep === 4) &&
        _vm.dataReferenceServices &&
        _vm.selectedTyreSized.length > 1)
        ? item.description
        : _vm.messageStep},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedStep == 2),expression:"selectedStep == 2"}],staticStyle:{"z-index":"100000"},on:{"mousemove":function($event){_vm.isActive = true},"mouseout":function($event){_vm.isActive = false}}},[(
            _vm.selectedStep === item.stepId &&
            [2, 3, 4].indexOf(_vm.selectedStep) >= 0
          )?_c('img',{staticClass:"cursor-pointer",attrs:{"src":_vm.urlInformative,"alt":"infoImage","width":"40"}}):_vm._e()]),_c('Popover',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedStep === 3 || _vm.selectedStep === 4),expression:"selectedStep === 3 || selectedStep === 4"}],attrs:{"imgUrl":_vm.urlInformative},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DescriptionColors')]},proxy:true}],null,true)})]},proxy:true}],null,true)},[(_vm.selectedStep === item.stepId && _vm.selectedStep === 1)?_c('Step1',{ref:"step1",refInFor:true,attrs:{"groupedServices":_vm.groupedServices,"allServicesSelectedIds":_vm.allServicesSelectedIds},on:{"updateservices":(value) => {
          _vm.tempSelectedServices = value;
        }}}):_vm._e(),(_vm.selectedStep === item.stepId && _vm.selectedStep === 2)?_c('Step2',{attrs:{"tyresDetails":_vm.$props.data.tyres.tyresDetails,"selectedTyre":_vm.selectedTyreSized},on:{"updateTyreSize":_vm.setTyreSizes}}):_vm._e(),(_vm.selectedStep === item.stepId && _vm.selectedStep === 3)?_c('Step3',{attrs:{"reference":_vm.selectedTyreSized[0],"groupedServices":_vm.groupedServices,"tyreReferenceDetails":_vm.$props.data.tyres.tyreReferenceDetails,"pneusServices":_vm.pneusServices},on:{"dataBrand":_vm.setReferenceArray,"updateTyre":_vm.setFrontTyre}}):_vm._e(),(_vm.selectedStep === item.stepId && _vm.selectedStep === 4)?_c('Step3',{attrs:{"reference":_vm.selectedTyreSized[1],"groupedServices":_vm.groupedServices,"tyreReferenceDetails":_vm.$props.data.tyres.tyreReferenceDetails,"pneusServices":_vm.pneusServices},on:{"dataBrand":_vm.setReferenceArray,"updateTyre":_vm.setRearTyre}}):_vm._e()],1)}),_c('div',{staticClass:"button flex gap-2"},[(_vm.selectedStep > 1)?_c('Button',{staticClass:"btn-primary align-btn-getmobile",attrs:{"id":"buttonNext","label":_vm.translateString('back')},on:{"action":function($event){return _vm.nextStep(-1)}}}):_vm._e(),_c('Button',{staticClass:"btn-primary align-btn-getmobile",attrs:{"id":"buttonNext","label":_vm.selectedStep == _vm.stepsPneus.length || !_vm.isSelectedAnyNeumatic
          ? _vm.translateString('confirmar')
          : _vm.translateString('continue'),"disabled":_vm.validateConfirmButton},on:{"action":function($event){return _vm.nextStep(1)}}})],1),_c('ModalSlot',{attrs:{"isActive":_vm.isActive && _vm.selectedStep == 2}},[_c('img',{attrs:{"src":_vm.urlImgDescription,"alt":"imgDescriptionPneus"}})]),(_vm.showWarningPopup)?_c('PopUpWarning',{attrs:{"text":_vm.translateString('keepSelectedTires'),"question":"","firstBtn":_vm.translateString('yes'),"secondBtn":_vm.translateString('no')},on:{"action":_vm.actionPopup}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }