<template>
  <div class="main_container">
    <FrameBasic
      v-for="item in stepsPneus"
      :key="item.stepId"
      :name="item.title"
      :description="
        (selectedStep !== 3 && selectedStep !== 4 && selectedStep !== 1) ||
        ((selectedStep === 3 || selectedStep === 4) &&
          dataReferenceServices &&
          selectedTyreSized.length > 1)
          ? item.description
          : messageStep
      "
      v-show="selectedStep === item.stepId"
    >
      <template v-slot:info>
        <span
          v-show="selectedStep == 2"
          style="z-index: 100000"
          v-on:mousemove="isActive = true"
          v-on:mouseout="isActive = false"
        >
          <img
            v-if="
              selectedStep === item.stepId &&
              [2, 3, 4].indexOf(selectedStep) >= 0
            "
            :src="urlInformative"
            alt="infoImage"
            width="40"
            class="cursor-pointer"
          />
        </span>
        <Popover
          :imgUrl="urlInformative"
          v-show="selectedStep === 3 || selectedStep === 4"
        >
          <template v-slot>
            <DescriptionColors />
          </template>
        </Popover>
      </template>
      <Step1
        v-if="selectedStep === item.stepId && selectedStep === 1"
        ref="step1"
        :groupedServices="groupedServices"
        :allServicesSelectedIds="allServicesSelectedIds"
        @updateservices="
          (value) => {
            tempSelectedServices = value;
          }
        "
      ></Step1>

      <Step2
        v-if="selectedStep === item.stepId && selectedStep === 2"
        :tyresDetails="$props.data.tyres.tyresDetails"
        :selectedTyre="selectedTyreSized"
        @updateTyreSize="setTyreSizes"
      ></Step2>

      <Step3
        v-if="selectedStep === item.stepId && selectedStep === 3"
        :reference="selectedTyreSized[0]"
        :groupedServices="groupedServices"
        :tyreReferenceDetails="$props.data.tyres.tyreReferenceDetails"
        :pneusServices="pneusServices"
        @dataBrand="setReferenceArray"
        @updateTyre="setFrontTyre"
      >
      </Step3>

      <Step3
        v-if="selectedStep === item.stepId && selectedStep === 4"
        :reference="selectedTyreSized[1]"
        :groupedServices="groupedServices"
        :tyreReferenceDetails="$props.data.tyres.tyreReferenceDetails"
        :pneusServices="pneusServices"
        @dataBrand="setReferenceArray"
        @updateTyre="setRearTyre"
      >
      </Step3>
    </FrameBasic>
    <div class="button flex gap-2">
      <Button
        id="buttonNext"
        class="btn-primary align-btn-getmobile"
        :label="translateString('back')"
        @action="nextStep(-1)"
        v-if="selectedStep > 1"
      />
      <Button
        id="buttonNext"
        class="btn-primary align-btn-getmobile"
        :label="
          selectedStep == stepsPneus.length || !isSelectedAnyNeumatic
            ? translateString('confirmar')
            : translateString('continue')
        "
        :disabled="validateConfirmButton"
        @action="nextStep(1)"
      />
    </div>

    <ModalSlot :isActive="isActive && selectedStep == 2">
      <img :src="urlImgDescription" alt="imgDescriptionPneus" />
    </ModalSlot>

    <PopUpWarning
      v-if="showWarningPopup"
      :text="translateString('keepSelectedTires')"
      question=""
      :firstBtn="translateString('yes')"
      :secondBtn="translateString('no')"
      @action="actionPopup"
    />

  </div>
</template>

<script>
import { translateString, getGroupsPneus } from "@/functions/functions.js";
import { stepsPneus } from "@/localData/stepsPneus.js";
import Button from "@/components/Buttons/Button.vue";
import ModalSlot from "@/components/ModalSlot/ModalSlot.vue";
import informativeIcon from "@/assets/icons/informativeIcon.png";
import pneusDescription from "@/assets/pneusDescription.png";
import PopUpWarning from "@/components/PopUp/PopUpWarning.vue";
import FrameBasic from "./components/FrameBasic.vue";
import Step1 from "./components/Step1.vue";
import Step2 from "./components/Step2.vue";
import Step3 from "./components/Step3.vue";
import DescriptionColors from "./components/DescriptionColors.vue";
import Popover from "../Popover/Popover.vue";

export default {
  name: "PneusForm",
  components: {
    Button,
    PopUpWarning,
    ModalSlot,
    FrameBasic,
    Step1,
    Step2,
    Step3,
    DescriptionColors,
    Popover,
  },
  props: {
    data: { type: Object, default: () => {} },
    allServicesSelectedIds: { type: Array, default: () => [] },
    servicesWithPromo: { type: Array, default: () => [] },
  },
  data() {
    return {
      selectedStep: 1,
      tempSelectedServices: [], // array de servicios seleccionado
      selectedTyreSized: [], // array de referencias seleccionadas
      selectedFrontTyre: {},
      selectedRearTyre: {},
      dataReferenceServices : false,

      tyresReferenceDetails: {},
      urlInformative: informativeIcon,
      urlImgDescription: pneusDescription,
      stepsPneus,
      groupedServices: getGroupsPneus(this.data),
      pneusServices: [],
      hasSameTyres: false,
      showWarningPopup: false,
      isActive: false,
    };
  },
  computed: {
    messageStep() {
      if (this.selectedStep === 1) {
        return this.$props.data.description;
      } else if (this.selectedStep === 3 && this.dataReferenceServices) {
        return translateString('selectTires')
      } else {
        return translateString('noOptionsAvailable')
      }
    },
    selectedReferencesStore() {
      return this.$store.getters.getSelectedReferences;
    },
    isSelectedAnyNeumatic() {
      if (this.pneusServices.length === 0) return false;
      const idGeneralService = this.pneusServices[0]["id_Service"]; // take the id of the firt position
      let isAdded = this.tempSelectedServices.includes(
        idGeneralService ? idGeneralService.toString() : "104"
      );
      return isAdded;
    },
    validateConfirmButton() {
      if (this.selectedStep === 2 && this.selectedTyreSized.length <= 0)
        return true;
      return (
        (this.selectedStep === 3 &&
          this.selectedTyreSized.length == 1 &&
          Object.keys(this.selectedFrontTyre).length == 0) ||
        (this.selectedStep === 4 &&
          this.selectedTyreSized.length == 2 &&
          Object.keys(this.selectedFrontTyre).length == 0 &&
          Object.keys(this.selectedRearTyre).length == 0) 
      );
    },
  },
  created() {
    const indexPneu = this.groupedServices.findIndex((item) =>
      item[0].name.includes("pneu")
    );
    if (indexPneu >= 0) {
      this.pneusServices = this.groupedServices[indexPneu] 
      this.hasSameTyres =
        this.$props.data.tyres?.isSameTyreForAllAxles ?? false;
    }
  },

  methods: {
    translateString,
    setReferenceArray(val) {
      this.dataReferenceServices = val
    },
    setTyreSizes(value) {
      if (value === "") return (this.selectedTyreSized = []);
      const arrayItems = new Set(value.split("-"));
      this.selectedTyreSized = [...arrayItems];
    },

    setFrontTyre(value) {
      this.selectedFrontTyre = value;
    },

    setRearTyre(value) {
      this.selectedRearTyre = value;
    },

    verifyIfExistInGroup(idGroup, arrayList) {
      let existIn = false;
      const filterGroup = this.$props.data.servicesList.filter(
        (e) => e.groupID === idGroup
      );
      filterGroup.forEach((ele) => {
        if (arrayList.indexOf(ele.id_Service.toString()) >= 0) {
          existIn = true;
        }
      });
      return existIn;
    },

    saveTyresIntoStore() {
      const arrayPrices = [];
      if (Object.keys(this.selectedFrontTyre).length > 0) {
        arrayPrices.push(this.selectedFrontTyre);
      }
      if (Object.keys(this.selectedRearTyre).length > 0) {
        arrayPrices.push(this.selectedRearTyre);
      }
      this.$store.commit("SET_SELECTED_REFERENCES", arrayPrices);
    },

    actionPopup(value) {
      this.showWarningPopup = false;
      if (value === "closeWarningPopup") {
        const idServicesStore = this.selectedReferencesStore.map(
          (item) => item.idService
        );
        const referenceStore = this.selectedReferencesStore.map(
          (item) => `${item.idService}##${item.modelName}`
        );
        const ids = this.tempSelectedServices
          .map((item) =>
            item.toString() !== this.pneusServices[0].id_Service.toString()
              ? item
              : null
          )
          .filter((e) => e !== null);
        this.$emit(
          "updateData",
          this.data.id,
          [...idServicesStore, ...ids],
          referenceStore
        );
      } else if (value === "next") {
        //this.$store.commit("SET_SELECTED_REFERENCES", []);
        this.nextStep(1, true);
      }
    },

    sendDataToParent() {
      //SEND DATA TO PARENT
      const ids = this.tempSelectedServices
        .map((item) =>
          item.toString() !== this.pneusServices[0].id_Service.toString()
            ? item
            : null
        )
        .filter((e) => e !== null);
      this.saveTyresIntoStore();
      const quantities = [];
      const models = [];
      if (Object.keys(this.selectedFrontTyre).length > 0) {
        quantities.push(this.selectedFrontTyre.idService);
        models.push(
          `${this.selectedFrontTyre.idService}##${this.selectedFrontTyre.modelName}`
        );
      }
      if (Object.keys(this.selectedRearTyre).length > 0) {
        quantities.push(this.selectedRearTyre.idService);
        models.push(
          `${this.selectedRearTyre.idService}##${this.selectedRearTyre.modelName}`
        );
      }
      this.$emit("updateData", this.data.id, [...quantities, ...ids], models);
    },

    nextStep(number, nextTo = false) {

      if (this.isSelectedAnyNeumatic) {
        if (
          this.selectedStep === 1 &&
          this.selectedReferencesStore.length > 0 &&
          !nextTo
        ) {
          return (this.showWarningPopup = true);
        }
        if (
          (this.selectedStep === 3 &&
            number > 0 &&
            this.selectedTyreSized.length == 1) ||
          (this.selectedStep === 4 &&
            number > 0 &&
            this.selectedTyreSized.length == 2)
        ) {
          //SEND DATA TO PARENT
          this.$store.commit("SET_SELECTED_REFERENCES", []);
          this.sendDataToParent();
          return;
        }
        this.selectedStep += number;
      } else {
        this.$emit("updateData", this.data.id, this.tempSelectedServices, []);
        this.$store.commit("SET_SELECTED_REFERENCES", []);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./PneusForm.scss";

.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.gap-2 {
  gap: 10px;
}
</style>
