<template>
  <div :class="isButton ? 'svg-container btn-icon' : 'svg-container'" style="margin-top: 0;">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        d="M6.9,12,1,6.1,2.4,4.7,6.9,9.1,15,1l1.4,1.4Z"
        transform="translate(-1 1)"
        :fill="iconColor"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    iconColor: {
      type: String,
      default: "#FFFFFF",
    },
    isButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
