<template>
  <div>
    <Card
      v-for="(group, index) in $props.groupedServices.filter(
        (el) => el.length > 0
      )"
      :key="index"
    >
      <div class="flex flex-row">
        <CheckboxContainer
          v-for="(service, index) in [group[0]]"
          :key="index"
          class="w-full no-details grid grid-cols-3"
          checkboxClass="checkbox-round radio-checkbox "
          containerClass="left"
          inputType="checkbox"
          :id="'service-' + service.id"
          :value="service.name"
          :name="'service-' + service.id"
          :isChecked="tempSelectedServices.includes(String(service.id))"
          @updateInput="updateInputServices($event)"
        >
          <LabelComplex :forLabel="'service-' + service.id">
            <div class="row">
              <div>
                <p class="text">
                  {{ service.price > 0 ? service.name : "Pneus" }}
                </p>
              </div>
            </div>
            <div v-if="service.price > 0" class="price-container">
              <p class="text price">
                {{ formatPrice(service.price, 2, "€") }}
              </p>
            </div>
          </LabelComplex>
        </CheckboxContainer>
      </div>
    </Card>
  </div>
</template>

<script>
import {
  translateString,
  formatPrice,
  getInfoByStock,
} from "@/functions/functions.js";

import Card from "@/components/Card/Card.vue";
import CheckboxContainer from "@/components/Checkbox/CheckboxContainer.vue";
import LabelComplex from "@/components/Checkbox/LabelComplex.vue";
export default {
  name: "Step1",
  components: {
    Card,
    CheckboxContainer,
    LabelComplex,
  },
  props: {
    groupedServices: { type: Array, default: () => [] },
    allServicesSelectedIds: { type: Array, default: () => [] },
  },
  data() {
    return {
      tempSelectedServices: [],
    };
  },

  created() {
    this.udpateServicesByState();
  },

  methods: {
    formatPrice,
    translateString,
    getInfoByStock,

    udpateServicesByState() {
      this.$props.groupedServices.forEach((groupService) => {
        if (groupService.length > 0) {
          groupService.forEach((service) => {
            if (
              this.$props.allServicesSelectedIds.includes(String(service.id))
            ) {
              if (service.groupID !== 1) {
                this.tempSelectedServices.push(String(service.id));
              }
              if (service.groupID === 1) {
                this.tempSelectedServices = [
                  ...this.tempSelectedServices,
                  String(groupService[0].id_Service),
                ];
              }
            }
          });
        }
      });
      this.$emit("updateservices", this.tempSelectedServices);
    },

    updateInputServices(data) {
      const id = data.id.replace("service-", "");
      if (data.checked) {
        this.tempSelectedServices.push(id);
      } else {
        const index = this.tempSelectedServices.indexOf(id);
        if (index > -1) {
          this.tempSelectedServices.splice(index, 1);
        }
      }
      this.$emit("updateservices", this.tempSelectedServices);
    },
  },
};
</script>
