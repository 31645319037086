<template>
  <div class="overlay">
    <div :class="data.id == 8 || data.id == 20 ? 'popup ' : 'popup'">
      <div class="popup-container" v-if="data.id === 8">
        <PneusForm
          :data="$props.data"
          :allServicesSelectedIds="$props.allServicesSelectedIds"
          :servicesWithPromo="$props.servicesWithPromo"
          @updateData="next"
        />
      </div>
      <div class="popup-container" v-else>
        <div class="popup-text">
          <h2 class="xl-title-boldest" v-if="data.name">{{ data.name }}</h2>
          <p class="sm-text-white" v-if="data.description">
            {{ data.description }}
          </p>
        </div>
        <div class="list-container mb-2">
          <Card
            v-for="(group, index) in groupedServices.filter(
              (el) => el.length > 0
            )"
            :key="index"
          >
            <CheckboxContainer
              v-for="(service, index) in group"
              :key="index"
              checkboxClass="checkbox-round radio-checkbox "
              containerClass="left"
              inputType="checkbox"
              :id="'service-' + service.id"
              :value="service.name"
              :name="'group-' + service.groupID"
              :isChecked="allServicesSelectedIds.includes(String(service.id))"
              @updateInput="updateInput($event)"
            >
              <LabelComplex :forLabel="'service-' + service.id">
                <div class="row">
                  <Badge
                    v-if="servicesWithPromo.includes(String(service.id))"
                    label="Promo"
                    position="relative "
                  />
                  <div>
                    <p class="text">{{ service.name }}</p>
                    <p v-if="service.description" class="xs-text-italic">
                      {{ service.description }}
                    </p>
                  </div>
                </div>
                <div
                  v-if="
                    servicesWithPromo.includes(String(service.id)) &&
                    service.price_Desc != null &&
                    service.price > 0
                  "
                  class="price-container"
                >
                  <p class="text old-price">
                    {{ formatPrice(service.price, 2, "€") }}
                  </p>
                  <p class="text price">
                    {{ formatPrice(service.price_Desc, 2, "€") }}
                  </p>
                </div>
                <div v-else class="price-container">
                  <p class="text price">
                    {{ formatPrice(service.price, 2, "€") }}
                  </p>
                </div>
              </LabelComplex>
            </CheckboxContainer>
            <div
              v-if="
                (data.userInput == 'S') ||
                data.id == 15 &&
                index ===
                  groupedServices.filter((el) => el.length > 0).length - 1
              "
              class="more-options"
            >
              <ButtonDefault @click="showMoreOptions = !showMoreOptions"
                >Mais opções</ButtonDefault
              >
              <div v-if="showMoreOptions">
                <CheckboxContainer
                  v-for="(option, index) in additionalOptions"
                  :key="index"
                  checkboxClass="checkbox-round radio-checkbox "
                  containerClass="left"
                  inputType="checkbox"
                  :id="'option-' + option.id"
                  :value="option.name"
                  :name="'option-group-' + option.groupID"
                  :isChecked="
                    allServicesSelectedIds.includes(String(option.id))
                  "
                  @updateInput="updateInput($event)"
                >
                  {{ option.name }}
                </CheckboxContainer>
              </div>
            </div>
          </Card>
        </div>
        <p
          v-if="data.id == -1"
          class="xs-text-italic-white align-text-italic-mobile"
        >
          {{ translateString("maintencanceDisclaimer") }}
        </p>
        <Button
          class="btn-primary align-btn-mobile"
          :label="translateString('confirmar')"
          @action="next"
        />
      </div>
      <CloseIcon @action="close" :isButton="true" />
    </div>
  </div>
</template>

<script>
import ButtonDefault from "@/components/Buttons/ButtonDefault.vue";
//import ButtonIcon from "@/components/Buttons/ButtonIcon.vue";
import CloseIcon from "@/assets/icons/CloseIcon.vue";
import Badge from "@/components/Badge/Badge.vue";
import Button from "@/components/Buttons/Button.vue";
import Card from "@/components/Card/Card.vue";
import CheckboxContainer from "@/components/Checkbox/CheckboxContainer.vue";
import LabelComplex from "@/components/Checkbox/LabelComplex.vue";
//import PneusForm from '@/views/PneusForm'
import PneusForm from "@/components/PneusForm/PneusForm.vue";

import {
  formatPrice,
  groupServices,
  translateString,
} from "@/functions/functions.js";

export default {
  name: "PopUpServices",
  components: {
    Badge,
    Button,
    Card,
    CheckboxContainer,
    CloseIcon,
    LabelComplex,
    ButtonDefault,
    PneusForm
  },
  props: {
    data: { type: Object, default: () => {} },
    allServicesSelectedIds: { type: Array, default: () => [] },
    servicesWithPromo: { type: Array, default: () => [] },
  },
  data() {
    return {
      tempServicesWithPromo: [],
      groupedServices: groupServices(this.data),
      tempSelectedServices: [],
      showMoreOptions: false,
      additionalOptions: [
        { id: 1, name: "Troca de Filtro de Ar", groupID: "group-1" },
        { id: 2, name: "Trocar Amortecedores", groupID: "group-2" },
        { id: 3, name: "Trocar Parabrisas", groupID: "group-3" },
        { id: 4, name: "Troca de Óleo", groupID: "group-4" },
        { id: 5, name: "Troca de Pastilhas de Freio", groupID: "group-5" },
        { id: 6, name: "Alinhamento e Balanceamento", groupID: "group-6" },
        { id: 7, name: "Troca de Correia Dentada", groupID: "group-7" },
        { id: 8, name: "Revisão de Injeção Eletrônica", groupID: "group-8" },
        { id: 9, name: "Troca de Velas de Ignição", groupID: "group-9" },
        { id: 10, name: "Limpeza de Radiador", groupID: "group-10" },
        { id: 11, name: "Troca de Bateria", groupID: "group-11" },
      ],
    };
  },
  created() {
    //Adicionar os serviços previamente selecionados ao array tempSelectedServices
    this.data.servicesList.forEach((service) => {
      if (this.allServicesSelectedIds.includes(String(service.id))) {
        this.tempSelectedServices.push(String(service.id));
      }
    });
    this.tempServicesWithPromo = [...this.servicesWithPromo];
  },
  methods: {
    formatPrice,
    translateString,
    updateInput(data) {
      const id = data.id.replace("service-", "");
      if (data.checked) {
        this.tempSelectedServices.push(id);
        this.checkServiceDependency(id, true);
      } else {
        const index = this.tempSelectedServices.indexOf(id);
        if (index > -1) {
          this.tempSelectedServices.splice(index, 1);
        }
        this.checkServiceDependency(id, false);
      }
    },
    checkServiceDependency(id, checked) {
      this.data.servicesList.forEach((service) => {
        service.campaigns.forEach((c) => {
          if (
            c["lst_services_parent"] != null &&
            c["lst_services_parent"].length > 0
          ) {
            if (c["lst_services_parent"].includes(String(id))) {
              //Adiciona ao array servicesWithPromo
              if (
                !this.servicesWithPromo.includes(String(service.id)) &&
                checked
              ) {
                this.servicesWithPromo.push(String(service.id));
                service["price_Desc"] = c.price_Desc;
              } else {
                if (this.servicesWithPromo.indexOf(String(service.id)) > -1) {
                  this.servicesWithPromo.splice(
                    this.servicesWithPromo.indexOf(String(service.id)),
                    1
                  );
                }
                if (service["price_Desc"] != null) {
                  delete service["price_Desc"];
                }
              }
            }
          }
        });
      });
    },
    close() {
      this.$emit("close", this.tempServicesWithPromo);
    },
    next(id, tmpSelectedServices, selectedReferences = []) {
      this.$emit(
        "updateSelectedServices",
        id ?? this.data.id,
        tmpSelectedServices ?? this.tempSelectedServices,
        true,
        selectedReferences
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/PopUp";
</style>
