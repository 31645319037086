<template>
  <Card class="container-pneus_card w-full">
    <h2 class="text-dark sm-text">{{ $props.reference }}</h2>
    <CheckboxContainer
      v-for="(reference, index1) in dataReference"
      :key="index1"
      class="w-full no-details"
      checkboxClass="checkbox-round radio-checkbox referenceChecks"
      containerClass="right checkbox-card grid grid-cols-3 no-spacing"
      inputType="checkbox"
      :id="`reference.${reference.reference}.${index1}`"
      :value="reference.name"
      :name="`reference_${reference.reference}_${index1}`"
      :isChecked="selectedReferences.includes(String(reference.reference))"
      @updateInput="updateInputBrand($event)"
    >
      <div class="fullCardContainer">
        <div class="grid grid-rows-3 containerIcons">
          <p class="xl-title-boldest text-highlight">
            {{ reference.brand }} / {{ reference.model }}
          </p>
          <div class="xs-text-grey no-details">{{ reference.name }}</div>
          <div class="icons iconsContainer">
            <div class="no-details iconsContainer-icons">
              <imgIconPopup
                :imgUrl="urlGasolinera"
                :tooltipText="translateString('efficiency')"
              ></imgIconPopup>
              {{ reference.efficiency }}
            </div>
            <div
              class="no-details iconsContainer-icons"
              style="justify-items: center; align-items: center"
            >
              <imgIconPopup
                :imgUrl="urlCloud"
                :tooltipText="translateString('grip')"
              ></imgIconPopup>
              <span>
                {{ reference.grip }}
              </span>
            </div>
            <div
              class="no-details iconsContainer-icons"
              style="justify-items: center; align-items: center"
            >
              <imgIconPopup
                :imgUrl="urlNoise"
                :tooltipText="translateString('noise')"
              ></imgIconPopup>
              {{ reference.noise }}
            </div>
          </div>
        </div>
        <div class="containerPrice grid grid-rows-3 no-details gap-1">
          <div
            class="w-full"
            style="
              line-height: 10px;
              display: flex;
              align-items: center;
              gap: 2px;
            "
          >
            <traficLightIcon
              v-if="false"
              class="elm-desktop"
              :isButton="true"
              :bgColor="getInfoByStock(reference.stock, true)"
              :tooltipText="getInfoByStock(reference.stock)"
            />
            <span class="font-bold">
              {{ formatPrice(reference.totalPrice, 2, "€") }} /pneu
            </span>
          </div>
          <div class="xs-text-grey no-details">
            {{
              `(${
                translateString("taxNotIncluded") +
                " | " +
                translateString("ambientalTax")
              }
              `
            }}
            <a
              target="_blank"
              href="https://www.valorpneu.pt/tabela-de-ecovalor/"
              >SIGPU</a
            >
            {{ translateString("assemblyBalancing") + ")" }}
          </div>
          <div
            class="w-full flex"
            style="
              height: 100%;
              width: 100%;
              display: flex;
              gap: 5px;
              align-items: center;
            "
          >
            <div class="w-full no-details container-quantity">
              <button
                class="container-add container-add-buttons"
                v-on:click="
                  addQuantity(
                    index1,
                    -1,
                    reference.selected,
                    `reference.${reference.reference}.${index1}`
                  )
                "
              >
                -
              </button>
              <div class="container-add container-add-number no-details">
                {{
                  $props.pneusServices.find(
                    (itm) => itm.id_Service === reference.quantity
                  )?.quantity ?? 0
                }}
              </div>
              <button
                class="container-add container-add-buttons"
                v-on:click="
                  addQuantity(
                    index1,
                    1,
                    reference.selected,
                    `reference.${reference.reference}.${index1}`
                  )
                "
              >
                +
              </button>
            </div>
            <traficLightIcon
              v-if="false"
              class="elm-mobile"
              :isButton="true"
              :bgColor="getInfoByStock(reference.stock, true)"
              :tooltipText="getInfoByStock(reference.stock)"
            />
          </div>
        </div>
      </div>
    </CheckboxContainer>
  </Card>
</template>

<script>
import {
  translateString,
  formatPrice,
  getInfoByStock,
} from "@/functions/functions.js";
import Card from "@/components/Card/Card.vue";
import CheckboxContainer from "@/components/Checkbox/CheckboxContainer.vue";
import traficLightIcon from "@/assets/icons/traficLightIcon.vue";
import gasolinera96 from "@/assets/icons/gasolinera-96.png";
import rainingCloud from "@/assets/icons/lluvia-96.png";
import noiseIcon from "@/assets/icons/volumen-96.png";
import imgIconPopup from "@/assets/icons/imgIconPopup.vue";
export default {
  name: "Step3",
  components: {
    Card,
    CheckboxContainer,
    traficLightIcon,
    imgIconPopup,
  },
  props: {
    groupedServices: { type: Array, default: () => {} },
    reference: { type: String, default: () => "" },
    tyreReferenceDetails: { type: Object, default: () => {} },
    pneusServices: { type: Array, default: () => {} },
  },
  data() {
    return {
      urlGasolinera: gasolinera96,
      urlCloud: rainingCloud,
      urlNoise: noiseIcon,
      selectedDetailsReferences: [],
      selectedTyreSize: "",
      dataReference: [],
      selectedReferences: [],
    };
  },
  computed: {
    selectedReferencesStore() {
      return this.$store.getters.getSelectedReferences;
    },
  },
  mounted() {
    this.setDataByReference();
  },

  methods: {
    formatPrice,
    translateString,
    getInfoByStock,

    sendEmitData(id) {
      const quantityNumber =
        this.$props.pneusServices.find(
          (item) => item.id_Service === this.dataReference[id].quantity
        )?.quantity ?? null;
      if (this.dataReference[id].quantity == null) this.$emit("updateTyre", {});
      this.$emit("updateTyre", {
        totalPrice: this.dataReference[id].totalPrice,
        idService: this.dataReference[id].quantity,
        reference: this.$props.reference,
        quantity: quantityNumber,
        modelName: this.dataReference[id].reference,
        brandName: `${quantityNumber} ${this.dataReference[id].brand} / ${this.dataReference[id].model}`,
        assemblyAndCalibration: this.dataReference[id].assemblyAndCalibration,
      });
    },

    updateInputBrand(data) {
      const splitData = data.id.split(".");
      if (!isNaN(splitData[2])) {
        this.selectedReferences = [];
        if (
          this.dataReference[splitData[2]].quantity === null &&
          data.checked
        ) {
          const [, , id] = data.id.split(".");
          this.addQuantity(id, 1, null, data.id);
        }
        if (data.checked) {
          const checkedCheckboxes = document.querySelectorAll(
            'input[type="checkbox"]:checked'
          );
          if (checkedCheckboxes.length > 1) {
            checkedCheckboxes.forEach((item) => {
              if (item.id !== data.id) {
                this.forceCheck(false, item.id);
                const [, , id] = item.id.split(".");
                this.dataReference[id].quantity = null;
              }
            });
          }
          this.selectedReferences.push(splitData[1]);
          this.dataReference[splitData[2]].selected = true;
          this.sendEmitData(splitData[2]);
        } else {
          this.dataReference[splitData[2]].selected = false;
          this.$emit("updateTyre", {});
        }
      }
    },

    forceCheck(toBe, id) {
      if (document.getElementById(id).checked !== toBe)
        document.getElementById(id).click();
    },

    addQuantity(id, number, isSelected, checkReference) {
      const currentId = this.dataReference[id].quantity;
      const indexPneus = this.groupedServices.findIndex((item) =>
        item[0].name.includes("pneu")
      );
      const indexOfNumber = this.groupedServices[indexPneus].findIndex(
        (e) => e.id_Service === currentId
      );
      const selctedQuantity =
        this.groupedServices[indexPneus][indexOfNumber + number]?.id_Service;
      if (!selctedQuantity) {
        if (number < 0) {
          this.dataReference[id].quantity = null;
          this.forceCheck(false, checkReference);
        }
        return;
      }
      this.dataReference[id].quantity =
        this.groupedServices[indexPneus][indexOfNumber + number].id_Service;
      this.forceCheck(true, checkReference);
      this.sendEmitData(id);
    },

    setDataByReference() {
      const array = this.$props.tyreReferenceDetails;
      if (Object.keys(array).length === 0) return;
      const includesReference = Object.keys(array).find((ele) =>
        this.$props.reference.includes(ele)
      );
      if (!includesReference) {
        this.$emit("dataBrand", false);
        return;
      }
      this.$emit("dataBrand", true);
      this.dataReference = this.$props.tyreReferenceDetails[includesReference]
        .map((e) => {
          const validatSelected = this.selectedReferencesStore.find(
            (item) => item.reference === e.reference
          );
          if (validatSelected) {
            this.selectedReferences.push(validatSelected.reference);
            return {
              ...e,
              quantity: validatSelected.idService,
              selected: true,
            };
          }
          return { ...e, quantity: null, selected: false };
        })
        .sort((a, b) => String(a.model).localeCompare(String(b.model)))
        .sort((a, b) => String(a.brand).localeCompare(String(b.brand)));
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  margin-top: 0px;
}

.container-pneus {
  height: 100%;
  display: flex;
  flex-direction: column;

  &_item {
    height: 80%;
  }

  &_card {
    min-height: 100%;
    max-height: max-content;
  }
}

.fullCardContainer {
  width: 100%;
  @include respond-to-min("md") {
    display: grid;
    grid-template-columns: 60% 40%;
    padding: 2px;
  }
  @include respond-to-max("sm") {
    display: flex;
    gap: 2px;
    height: auto;
    flex-direction: column;
  }
}
.containerIcons {
  @include respond-to-min("md") {
    display: grid;
    height: 100%;
    grid-template-rows: 30% 40% 30%;
  }
  @include respond-to-max("md") {
    display: flex;
    flex-direction: column;
  }
}

.iconsContainer {
  @include respond-to-max("md") {
    width: 90%;
  }
  @include respond-to-min("md") {
    width: 80%;
  }
  &-icons {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
}

.containerPrice {
  display: grid;
  @include respond-to-min("md") {
    grid-template-rows: 25% 55% 20%;
  }
  @include respond-to-max("md") {
    grid-template-rows: 20% 60% 20%;
  }
}
</style>
