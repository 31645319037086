<template>
  <div class="services">
    <Header
      :isHeader="true"
      :back="true"
      :carInfo="true"
      :mobileResume="true"
      :dealer="true"
    />
    <Wrapper :ismain="true">
      <template v-slot:sidebar>
        <Sidebar :desktopResume="true" :servicesScreen="true" :dealer="true" />
      </template>
      <Title titleStyle="title" :text="translateString('selectServices')" />
      <BoxContainer
        :data="services"
        :groupServicesSelected="groupServicesSelected"
        :groupsWithPromo="groupsWithPromo"
        :campaignInfo="campaignInfo"
        :hasCampaigns="hasCampaigns"
        @action="openServicesPopUp"
      />

      <br />
      <template v-slot:footer>
        <br />
        <Footer>
          <Button
            id="getWashServices"
            class="btn-primary align-btn-getmobile"
            :label="translateString('continue')"
            @action="hasServicesSelected('getWashServices')"
          />
          <div v-if="total" class="fluid-element">
            <ServicesTotal screen="mobile" />
          </div>
        </Footer>
      </template>
    </Wrapper>
    <Modal :msg="callError" :isActive="callError != ''" />
    <PopUpWarning
      v-if="popupWarningShow"
      :text="translateString('noServiceSelected') + carName"
      :question="translateString('wantToProceed')"
      :firstBtn="translateString('addServices')"
      :secondBtn="translateString('continue')"
      @action="actionPopUp($event)"
    />
    <PopUpServices
      v-if="Object.keys(servicesInfo).length > 0"
      :data="servicesInfo"
      :allServicesSelectedIds="allServicesSelectedIds"
      :servicesWithPromo="servicesWithPromo"
      @close="cancelSelectedServices($event)"
      @updateSelectedServices="updateSelectedServices"
    />
  </div>
</template>

<script>
import BoxContainer from "@/components/Box/BoxContainer.vue";
import Button from "@/components/Buttons/Button.vue";
import Footer from "@/components/Footer/Footer.vue";
import Header from "@/components/Header/Header.vue";
import Modal from "@/components/Modal/Modal.vue";
import PopUpServices from "@/components/PopUp/PopUpServices.vue";
import PopUpWarning from "@/components/PopUp/PopUpWarning.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import Title from "@/components/Title/Title.vue";
import ServicesTotal from "@/components/UpdatedResume/ServicesTotal.vue";
import Wrapper from "@/components/Wrapper/Wrapper.vue";
import { mapState } from "vuex";

import { translateString } from "@/functions/functions.js";

export default {
  name: "Services",
  components: {
    BoxContainer,
    Button,
    Footer,
    Header,
    Modal,
    PopUpServices,
    PopUpWarning,
    ServicesTotal,
    Sidebar,
    Title,
    Wrapper,
  },
  data() {
    return {
      allServicesStore: this.$store.services,
      services: this.$store.getters.getMappedServices.sort(
        (a, b) => (a.id > b.id && 1) || -1
      ), // retorna os serviços ordenados por id (pondo o -1 em primeiro)
      allServicesSelected: this.$store.state.allServicesSelected, // array que guarda um objeto com os ids de todos os serviços selecionados e respectivos grupos
      total: this.$store.state.updatableServicesTotal,
      carName: this.$store.state.carName
        ? this.$store.state.carName
        : this.$store.state.carNF.brand + " " + this.$store.state.carNF.model,

      servicesInfo: {}, // objeto para guardar os dados dos serviços do grupo clicacdo (Box)
      popupWarningShow: false, // bool para aparecer a popup de aviso quando nenhum serviço foi selecionado

      groupServicesSelected: [], // array com os ids dos grupos selecionados
      allServicesSelectedIds: [], // array que guarda os ids de todos os serviços selecionados

      groupsWithPromo: [], // array com os ids dos grupos que tem promoção
      servicesWithPromo: [], // array com os ids dos serviços que tem promoção e que os pais estão seleciondos
      campaignInfo: this.$store.getters.getCampaignInfo,
      hasCampaigns: this.$store.state.hasCampaigns,
      campaignsUsed: [],
      itemSelectedMarking: false,
    };
  },
  computed: {
    finalError() {
      return this.$store.state.finalError;
    },
    servicesReady() {
      return this.$store.state.washServicesReady;
    },
    callError() {
      return this.$store.state.callError;
    },
    isServiceIdMinusOne() {
      return this.$store.state.isServiceIdMinusOne;
    },
    ...mapState(["markingServiceSelected"]),
    selectedReferencess() {
      return this.$store.state.selectedReferences;
    },
  },
  mounted() {
    this.markingSelectedServices();
  },
  created() {
    this.$store.dispatch("selectOption", {
      selectedValue: "finalError",
      value: false,
    });
    if (this.$store.state.carPlate == "") {
      this.$router.replace({ name: "Homepage" });
    }
    this.$store.dispatch("clearSelectedInfo", "Services");

    if (this.hasCampaigns) this.checkCampaigns();
    //Atualiza os grupos selecionados
    this.updateGroupsSelected();
    //Atualiza os ids dos serviços selecionados
    this.updateServicesSelectedIds();
  },

  methods: {
    translateString,

    markingSelectedServices() {
      console.log("Vou marcar serviços");
      // aqui eu faço verificação, pois se o serviço retornar items com virgulas ou espaços, eu trato, assim a comparação de ids será plena.
      let sortedServices = this.$store.getters.getMappedServices.sort((a, b) =>
        String(a.id).trim().replace(",", "") >
        String(b.id).trim().replace(",", "")
          ? 1
          : -1
      );

      if (
        this.markingServiceSelected &&
        this.markingServiceSelected.lstServicesId
      ) {
        console.log("Entrei no IF");
        let selectedIds = this.markingServiceSelected.lstServicesId.flatMap(
          (idString) => idString.split(",").map((id) => String(id).trim())
        );

        sortedServices.forEach((service) => {
          console.log("Tenho serviços: " + service);
          let isGroupSelected = false; //Flag para verificar se algum serviço do grupo está selecionado
          let tempSelectedServices = []; // Array temporária para armazenar serviços selecionados

          service.servicesList.forEach((subService, index) => {
            if (selectedIds.includes(String(subService.id).trim())) {
              this.$set(service.servicesList, index, {
                ...subService,
                selected: true,
              });
              tempSelectedServices.push(String(subService.id).trim());
              isGroupSelected = true; // Set the flag to true if a service is selected
              this.itemSelectedMarking = true;
            }
          });
          console.log("isGroupSelected: " + isGroupSelected);

          // Chamar updateSelectedServices com o ID do serviço e o array tempSelectedServices
          this.updateSelectedServices(service.id, tempSelectedServices);

          // Defino a propriedade selecionada do grupo com base na sinalização
          this.$set(service, "selected", isGroupSelected);
        });
      }
    },
    checkCampaigns() {
      const campaignUuid = this.campaignInfo.uuid;
      let addPromoServices =
        this.allServicesSelected.length == 0 ? true : false;

      this.services.forEach((service) => {
        service.servicesList.forEach((s) => {
          if (s.campaigns.length > 0 && s.campaigns != null) {
            s.campaigns.forEach((c) => {
              if (c.campaign_id == campaignUuid) {
                if (addPromoServices) {
                  this.updateSelectedServices(
                    service.id,
                    [String(s.id)],
                    false
                  );
                }
              }
            });
          }
        });
      });
    },
    openServicesPopUp(data) {
      this.servicesInfo = data;
    },
    updateSelectedServices(
      id,
      tempSelectedServices,
      replace = true,
      selectedReferences = []
    ) {

      const servicesToAdd = {
        group: id,
        services: tempSelectedServices,
      };
      if (id === 8) {
        replace = true;
        servicesToAdd.references = selectedReferences;
      }
      /**
       * Se o array allServicesSelected não estiver vazio, verifica se o grupo alterado já exste no array
       * - Se existir, e tiver serviços selecionados, atualiza os serviços selecionados
       * - Se existir, e não tiver serviços selecionados, remove o grupo do array
       * - Se não existir, adiciona o objeto servicesToAdd ao array
       * Se o array allServicesSelected estiver vazio, adiciona o objeto servicesToAdd ao array
       */
      if (this.allServicesSelected.length > 0) {
        const index = this.allServicesSelected.findIndex(
          (item) => item.group == id
        );
        if (index > -1) {
          if (tempSelectedServices.length > 0) {
            if (replace) {
              this.allServicesSelected[index].services = tempSelectedServices;
              if (id === 8)
                this.allServicesSelected[index].references = selectedReferences;
            } else {
              this.allServicesSelected[index].services = [
                ...this.allServicesSelected[index].services,
                ...tempSelectedServices,
              ];
            }
          } else {
            this.allServicesSelected.splice(index, 1);
          }
        } else {
          if (tempSelectedServices.length > 0) {
            this.allServicesSelected.push(servicesToAdd);
          }
        }
      } else {
        if (tempSelectedServices.length > 0)
          this.allServicesSelected.push(servicesToAdd);
      }

      //Atualiza os grupos selecionados
      this.updateGroupsSelected();
      //Atualiza os ids dos serviços selecionados
      this.updateServicesSelectedIds();

      this.actionPopUp("closeServicesPopup");
    },
    cancelSelectedServices(event) {
      this.servicesWithPromo = event;
      this.actionPopUp("closeServicesPopup");
    },
    updateServicesSelectedIds() {
      //Faz reset aos ids dos serviços selecionados
      this.allServicesSelectedIds = [];

      //Adiciona o id de todos os serviços selecionados ao array allServicesSelectedIds
      this.allServicesSelected.forEach((service) => {
        service.services.forEach((serviceId) => {
          if (serviceId === "-1") {
            this.$store.commit("SET_SERVICE_ID_MINUS_ONE", true);
          }
          this.allServicesSelectedIds.push(String(serviceId));
        });
      });

      this.checkPromoDependecies();

      this.updateTotalPrice();
    },
    checkPromoDependecies() {
      this.services.forEach((service) => {
        service.servicesList.forEach((s) => {
          if (s.campaigns != null && s.campaigns.length > 0) {
            s.campaigns.forEach((c) => {
              if (
                c.lst_services_parent == null ||
                c.lst_services_parent.length == 0
              ) {
                if (this.servicesWithPromo.indexOf(String(s.id)) == -1) {
                  this.servicesWithPromo.push(String(s.id));
                }
                if (this.groupsWithPromo.indexOf(String(service.id)) == -1) {
                  this.groupsWithPromo.push(String(service.id));
                }
                if (c.price_Desc != null) s["price_Desc"] = c.price_Desc;
              }

              if (
                c.lst_services_parent != null &&
                c.lst_services_parent.length > 0
              ) {
                if (
                  this.allServicesSelectedIds.includes(
                    String(c.lst_services_parent)
                  )
                ) {
                  if (this.servicesWithPromo.indexOf(String(s.id)) == -1) {
                    this.servicesWithPromo.push(String(s.id));
                  }
                  if (this.groupsWithPromo.indexOf(String(service.id)) == -1) {
                    this.groupsWithPromo.push(String(service.id));
                  }
                  s["price_Desc"] = c.price_Desc;
                } else {
                  //Se o serviço não estiver selecionado, remove o serviço do array
                  if (this.servicesWithPromo.indexOf(String(s.id)) > -1) {
                    this.servicesWithPromo.splice(
                      this.servicesWithPromo.indexOf(String(s.id)),
                      1
                    );
                  }
                  if (this.groupsWithPromo.indexOf(String(service.id)) > -1) {
                    this.groupsWithPromo.splice(
                      this.groupsWithPromo.indexOf(String(service.id)),
                      1
                    );
                  }
                  //Remove propriedade price_Desc do serviço
                  if (s["price_Desc"] != null) {
                    delete s["price_Desc"];
                  }
                }
              }
            });
          }
        });
      });
    },
    updateGroupsSelected() {
      //Faz reset aos grupos selecionados
      this.groupServicesSelected = [];
      //Adiciona o id de todos os grupos selecionados ao array groupServicesSelected
      this.allServicesSelected.forEach((service) => {
        this.groupServicesSelected.push(service.group);
      });
    },
    updateTotalPrice() {
      //Faz reset ao total
      this.total = 0;
      const serviceOnRequest = [];
      let selectedReferences = this.$store.getters.getSelectedReferences;
      //Adiciona o preço de todos os serviços selecionados ao total

      this.allServicesSelectedIds.forEach((id) => {
        this.services.forEach((service) => {
          service.servicesList.forEach((s) => {
            if (s.id == id) {
              /**
               * Se o preço não for um código (-1 ou -2), adiciona o preço ao total
               * Se for um código, adiciona ao array para o disclaimer
               */
              if (s.price_Desc != null && s.price_Desc != undefined) {
                this.total += s.price_Desc;
              } else if (s.price >= 0) {
                this.total += s.price;
              } else {
                if (service.id === 8 && s.price < 0) {
                  const itemsReferences = selectedReferences.find(
                    (item) => item.idService === s.id_Service
                  );
                  this.total += 
                    Number(itemsReferences.totalPrice) *
                      Number(itemsReferences.quantity) +
                    Number(itemsReferences.quantity) *
                      Number(itemsReferences.assemblyAndCalibration);
                  selectedReferences = [
                    ...selectedReferences.filter(
                      (ee) => ee.reference !== itemsReferences.reference
                    ),
                  ];
                  // itemsReferences.forEach((item) => {
                  //   this.total +=
                  //     Number(item.totalPrice) * Number(item.quantity);
                  // });
                } else {
                  serviceOnRequest.push(s.name);
                }
              }
            }
          });
        });
      });

      this.$store.dispatch("selectOption", {
        selectedValue: "totalDisclaimer",
        value: serviceOnRequest,
      });

      this.$store.dispatch("selectOption", {
        selectedValue: "updatableServicesTotal",
        value: this.total,
      });
    },
    hasServicesSelected(id) {
      if (this.allServicesSelected.length == 0 && !this.itemSelectedMarking) {
        this.popupWarningShow = true;
      } else {
        this.next(id);
      }
    },
    actionPopUp(event) {
      if (event == "closeServicesPopup") {
        this.servicesInfo = {};
      } else if (event == "closeWarningPopup") {
        this.popupWarningShow = false;
      } else if (event == "next") {
        this.next("getWashServices");
      }
    },
    saveAllInfo() {
      const workIds = [];
      const maintenanceList = [];
      let complementaryList = [];
      let referenceList = [
        ...this.$store.getters.getSelectedReferences.map(
          (item) => `${item.idService}##${item.modelName}`
        ),
      ];

      let selectedReferences = this.$store.getters.getSelectedReferences;
      const servicesSelectedCompletedList = [];
      //array com os ids dos grupos e dos serviços selecionados
      this.$store.dispatch("selectOption", {
        selectedValue: "allServicesSelected",
        value: this.allServicesSelected,
      });

      //total preço dos serviços
      this.$store.dispatch("selectOption", {
        selectedValue: "servicesTotal",
        value: this.total,
      });

      this.services.forEach((service) => {
        if (service.id == -1) {
          service.servicesList.forEach((s) => {
            if (this.allServicesSelectedIds.includes(String(s.id))) {
              if (s.external == true) {
                workIds.push(s.id);
              } else {
                maintenanceList.push(s.id);
              }

              //Listar as campanhas utilizadas
              if (s.campaigns != null && s.campaigns.length > 0) {
                const campaign = s.campaigns.find((el) => {
                  return el.price_Desc == s.price_Desc;
                });

                if (campaign != null) {
                  if (!this.campaignsUsed.includes(campaign.campaign_id)) {
                    this.campaignsUsed.push(campaign.campaign_id);
                  }
                }
              }

              /**
               * Se o grupo não existir no array, adiciona o grupo ao array
               * Se existir adicionar apenas os serviços e atualiza o preço
               */
              const index = servicesSelectedCompletedList.findIndex(
                (item) => item.group.id == service.id
              );
              if (index == -1) {
                servicesSelectedCompletedList.push({
                  group: {
                    id: service.id,
                    name: service.name,
                    price: s.price_Desc != null ? s.price_Desc : s.price,
                    oldPrice: s.price,
                  },
                  services: [
                    {
                      id: s.id,
                      name: s.name,
                      price: s.price_Desc != null ? s.price_Desc : s.price,
                    },
                  ],
                });
              } else {
                servicesSelectedCompletedList.forEach((item) => {
                  if (item.group.id == service.id) {
                    item.group.price +=
                      s.price_Desc != null ? s.price_Desc : s.price;
                    item.group.oldPrice += s.price;
                    item.services.push({
                      id: s.id,
                      name: s.name,
                      price: s.price_Desc != null ? s.price_Desc : s.price,
                    });
                  }
                });
              }
            }
          });
        } else {
          service.servicesList.forEach((s) => {
            if (this.allServicesSelectedIds.includes(String(s.id))) {
              complementaryList = [...complementaryList , ...this.allServicesSelectedIds.filter(ee=> ee === String(s.id))]
              //complementaryList.push(s.id);

              //Listar as campanhas utilizadas
              if (s.campaigns != null && s.campaigns.length > 0) {
                const campaign = s.campaigns.find((el) => {
                  return el.price_Desc == s.price_Desc;
                });

                if (campaign != null) {
                  if (!this.campaignsUsed.includes(campaign.campaign_id)) {
                    this.campaignsUsed.push(campaign.campaign_id);
                  }
                }
              }

              const index = servicesSelectedCompletedList.findIndex(
                (item) => item.group.id == service.id
              );
              if (index == -1) {
                
                const res = this.allServicesSelectedIds.filter(e => e == s.id ).map(item=> {
                  const element = selectedReferences.find(selec=> selec.idService == item)
                  let price = null
                  if(element){
                    price = element.totalPrice * Number(element.quantity)
                    selectedReferences = selectedReferences.filter(e=>e.modelName != element.modelName)
                  }
                  return { 
                      id: item,
                      name: price?element.brandName:s.name,
                      price: price?price:s.price_Desc != null ? s.price_Desc : s.price,
                   }
                })

                servicesSelectedCompletedList.push({
                  group: {
                    id: service.id,
                    name: service.name,
                    price: s.price_Desc != null ? s.price_Desc : s.price,
                    oldPrice: s.price,
                  },
                  services: res

                });
              } else {
                servicesSelectedCompletedList.forEach((item) => {
                  if (item.group.id == service.id) {
                    item.group.price +=
                      s.price_Desc != null ? s.price_Desc : s.price;
                    item.group.oldPrice += s.price;
                    const res = this.allServicesSelectedIds.filter(e => e == s.id ).map(item=> {
                      const element = selectedReferences.find(selec=> selec.idService == item)
                      let price = null
                      if(element){
                        price = element.totalPrice * Number(element.quantity)
                        selectedReferences = selectedReferences.filter(e=>e.modelName != element.modelName)
                      }
                      return { 
                      id: item,
                      name: price?element.brandName:s.name,
                      price: price?price:s.price_Desc != null ? s.price_Desc : s.price,
                      }
                    
                    }) 
                    item.services = [
                      ...item.services , ...res
                    ]
                  }
                });
              }
            }
          });
        }
      });

      // arrays para o serviço da marcação (post)
      this.$store.dispatch("selectOption", {
        selectedValue: "workIds",
        value: workIds,
      });
      this.$store.dispatch("selectOption", {
        selectedValue: "maintenanceList",
        value: maintenanceList,
      });
      this.$store.dispatch("selectOption", {
        selectedValue: "complementaryList",
        value: complementaryList,
      });
      this.$store.dispatch("selectOption", {
        selectedValue: "referenceList",
        value: referenceList,
      });
      this.$store.dispatch("selectOption", {
        selectedValue: "campaignsUsed",
        value: this.campaignsUsed,
      });

      // array com ids, nomes e preços dos serviços selecionados
      this.$store.dispatch("selectOption", {
        selectedValue: "servicesSelectedCompletedList",
        value: servicesSelectedCompletedList,
      });
      
    },
    next(id) {
      this.saveAllInfo();

      if (this.servicesReady) {
        this.$router.push({ name: "WashServices" });
      } else {
        this.$store.dispatch("getWashServices");
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: id,
        });
      }
    },
  },
  watch: {
    servicesReady() {
      if (this.servicesReady == true) {
        this.$router.push({ name: "WashServices" });
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: false,
        });
      }
    },
    finalError() {
      if (this.finalError == true) {
        this.$router.push({ name: "ErrorForm" });
      }
    },
  },
};
</script>
