<template>
  <div :class="modalClass + 'modal'">
    <slot> </slot>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modalClass() {
      return this.isActive ? "modal-active " : "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Modal";
</style>
