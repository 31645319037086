import { translateString } from "@/functions/functions.js";
export const stepsPneus = [
  {
    stepId: 1,
    name: "selectQuantity",
    title: "Pneus",
    description: translateString("selectNumberOfTires"),
  },
  {
    stepId: 2,
    name: "medidas",
    title: "Pneus",
    description: translateString("selectTireSize"),
  },
  {
    stepId: 3,
    title: "Pneus",
    name: "selectPhneus",
    description: translateString("selectFrontTires"),
  },
  {
    stepId: 4,
    title: "Pneus",
    name: "selectPhneus",
    description: translateString("selectRearTires"),
  },
];